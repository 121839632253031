<template>
    <div class="container">
        <div class="modal">
            <div class="modal__header">
                <img src="../img/components/settings_icons/shutdown.svg" alt="">
                <span>Выключение</span>
            </div>
            <div class="modal__buttons button">
                <figure>
                    <img src="../img/components/settings_icons/shutdown-system.svg" class="button__system"
                         @click="shutdownSystem"/>
                    <figcaption>Выключение системы</figcaption>
                </figure>
                <figure>
                    <img src="../img/components/settings_icons/shutdown-injector.svg" class="button__injector"
                        @click="shutdownInjector"
                    />
                    <figcaption>Выключение инъектора</figcaption>
                </figure>

            </div>
            <div class="modal__footer footer">
                <button class="footer__cancel" @click="closeModal">Отменить</button>
            </div>
        </div>
    </div>
</template>

<script>

    import {mapGetters} from 'vuex';

    export default {
        methods: {
            closeModal() {
                this.$emit('close-modal');
            },
            shutdownSystem() {
                this.$store.dispatch('resetStore');
                if (this.getInstallationStatus) {
                    this.$store.dispatch('injector/reset_installation', false)
                }
                this.$emit('close-modal');
            },
            shutdownInjector() {
                this.$emit('close-modal');
                if (this.getInstallationStatus) {
                    this.$store.dispatch('injector/reset_installation', false)
                }
            },
        },
        computed: {
            ...mapGetters('injector', {
                getInstallationStatus: 'getInstallationStatus',
            }),
        },
    }
</script>

<style scoped lang="scss">
    .container {
        width: 100%;
        height: 100%;
    }
    .modal {
        background: #7195AF;
        position: absolute;
        border: 3px solid black;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 10px 10px 20px;
        z-index: 10;



        &__header {
            display: flex;
            align-items: center;
            background: #4D7EA1;
            border-radius: 5px;
            padding: 5px 100px 5px 5px;
            border: 1px solid black;
            margin-bottom: 20px;
            span {
                color: #fff;
                font-size: 24px;
                margin-left: 10px;

            }
        }

        &__buttons {
            background: #DEE9E2;
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 15px 0;
            margin-bottom: 20px;
            border-radius: 5px;

            figure {
                width: 90px;
                text-align: center;
                font-weight: 700;
            }

            img {
                min-width: 90%;
                height: 70px;
                margin-bottom: 15px;
                cursor: pointer;
            }
        }

        &__footer {
            display: flex;
            justify-content: flex-end;

            button {
                background-color: #4D7EA1;
                padding: 5px 15px;
                font-weight: 700;
                box-shadow: inset 0px 0.5px #fff,
                            1px 0px #fff inset,
                            0px -3px #436D8B inset,
                            -3px 0px #436D8B inset;

                &:active {
                    box-shadow: none;
                }
            }


        }
    }
</style>